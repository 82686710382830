import { isSameDay, setYear } from 'date-fns';
import useGetReservationByConsumer from './queries/reservations-api/useGetReservationByConsumer';
import { ReservationStatus } from '@/utils/reservations';

type GetStatusStudent = {
  studentData: any;
  currentReservationStatus: string;
  currentReservationDate: Date;
};

export type StudentStatus = {
  isConcurrentAbsent: boolean;
  isBirthday: boolean;
  hasTreatment: boolean;
  isAllergic: boolean;
  hasDiseases: boolean;
};

// TODO: Type studentData or refactor -> It depends of backend structure refactor in consumers
const useGetStudentStatus = ({
  studentData,
  currentReservationStatus,
  currentReservationDate
}: GetStatusStudent): StudentStatus => {
  const { data: allReservations, isPending } = useGetReservationByConsumer(
    studentData.id || undefined
  );

  const validateConcurrentAbsent = () => {
    if (!allReservations) return false;

    // If current reservation is completed, return false
    if (currentReservationStatus === ReservationStatus.Completed) {
      return false;
    }

    // Find the index of current reservation or next closest one
    const currentIndex = allReservations.findIndex((res) => {
      const resDate = new Date(res.startTime / 1000);
      return resDate >= currentReservationDate;
    });

    if (currentIndex < 3) return false; // Not enough previous reservations

    // Get the 3 previous reservations
    const previousReservations = allReservations.slice(
      currentIndex - 3,
      currentIndex
    );

    // Check if we got exactly 3 previous reservations
    if (previousReservations.length !== 3) return false;

    // Check if none of these 3 reservations are completed
    const hasCompletedAttendance = previousReservations.some(
      (res) => res.reservationStatus.name === ReservationStatus.Completed
    );

    return !hasCompletedAttendance;
  };

  const isBirthday = isSameDay(
    setYear(new Date(studentData.birthdate), new Date().getFullYear()),
    new Date()
  );

  const hasTreatment = studentData.additionalData.treatments.some(
    (t: any) => t.value
  );

  const isAllergic = studentData.additionalData.diseases
    .slice(2)
    .some((d: any) => d.value);

  const hasDiseases = studentData.additionalData.diseases
    .slice(0, 2)
    .some((d: any) => d.value);

  const isConcurrentAbsent = isPending ? false : validateConcurrentAbsent();

  return {
    isConcurrentAbsent,
    isBirthday,
    hasTreatment,
    isAllergic,
    hasDiseases
  };
};

export default useGetStudentStatus;
