import type { ReservationInterface } from '@/components/common/tables/tables.types';
import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservationsByServiceId = <TResult,>(
  serviceId: string | null,
  filterToday = false,
  date = new Date()
) => {
  const organizationName = useOrganizationName();

  return useQuery<ReservationInterface[]>({
    queryKey: ['reservations', serviceId, filterToday, organizationName],
    queryFn: async () => {
      if (!serviceId) {
        return [];
      }

      const startOfDay = new Date(date.setHours(0, 0, 0, 0)).getTime(); // Timestamp init of the day
      const endOfDay = new Date(date.setHours(23, 59, 59, 999)).getTime(); // Timestamp end of the day

      const URI = filterToday
        ? `filter.sale.service.id=string.eq.${serviceId}&filter.startTime=number.between.${startOfDay},${endOfDay}&sortBy=sale.saleConsumers.consumer.firstName&sortDirection=ASC`
        : `filter.sale.service.id=string.eq.${serviceId}&sortBy=sale.saleConsumers.consumer.firstName&sortDirection=ASC`;

      const response = await api.reservations.getReservationByServiceId(URI);
      return response;
    },
    refetchInterval: 100 * 5000,
    refetchOnReconnect: true,
    enabled: !!serviceId
  });
};

export default useGetReservationsByServiceId;
