import Navbar from '@/components/section/navbar/navbar';
import SidebarV2 from '@/components/section/sidebar/SidebarV2';
import useAuth from '@/hooks/useAuth';
import { Outlet } from 'react-router-dom';
import CallbackPage from './Callback';

const Root = () => {
  const { tokenIsPending } = useAuth();

  if (tokenIsPending) {
    return <CallbackPage />;
  }

  return (
    <div>
      <Navbar />
      <div className="relative grid grid-flow-col [grid-auto-columns:max-content_auto]  w-full overflow-auto h-[calc(100vh-61px)] lg:h-[calc(100vh-69px)]">
        <div className="flex flex-col min-w-0 col-start-2 row-start-1">
          <main className="flex-1 px-6 pt-6 overflow-y-auto md:pt-6 bg-base-200">
            <Outlet />
          </main>
        </div>
        <div className="sticky top-0 left-0 items-start visible block w-auto grid-cols-1 col-start-1 grid-rows-1 row-start-1 overflow-y-auto shadow-md pointer-events-auto justify-items-start overscroll-auto h-[calc(100vh-61px)] lg:h-[calc(100vh-69px)]">
          <SidebarV2 />
        </div>
      </div>
    </div>
  );
};
export default Root;
