import { ReservationInterface } from '@/components/common/tables/tables.types';
import api from '@/services/api';
import { TGetReservations } from '@/services/reservations';
import { ReservationStatus } from '@/utils/reservations';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePatchReservation = ({
  disabledRefetch = false
}: {
  disabledRefetch?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['patchReservation'],
    mutationFn: ({
      status,
      reservationId,
      data
    }: {
      status: string;
      reservationId: string;
      data?: any;
    }) => {
      if (disabledRefetch) {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === 'reservation' ||
              query.queryKey[0] === 'reservations'
          },
          (oldData: ReservationInterface[] | TGetReservations | undefined) => {
            if (oldData && !Array.isArray(oldData)) {
              return {
                ...oldData,
                results: oldData.results.map((data) => {
                  if (data.id === reservationId) {
                    return {
                      ...data,
                      reservationStatus: {
                        name: Object.keys(ReservationStatus).find((key) =>
                          status === 'complete'
                            ? key === ReservationStatus.Completed
                            : key.toLocaleLowerCase() === status
                        ) as any
                      }
                    };
                  }
                  return data;
                })
              };
            }

            if (oldData?.length) {
              return oldData.map((data) => {
                if (data.id === reservationId) {
                  return {
                    ...data,
                    reservationStatus: {
                      name: Object.keys(ReservationStatus).find((key) =>
                        status === 'complete'
                          ? key === ReservationStatus.Completed
                          : key.toLocaleLowerCase() === status
                      ) as any
                    }
                  };
                }
                return data;
              });
            }
            return oldData;
          }
        );
      }
      return api.reservations.patchReservation({ status, reservationId }, data);
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'reservation' ||
          query.queryKey[0] === 'reservations'
      });
      const errorMessage = error.response?.data.message;
      console.log(error);
      toast.error(t('toast.error', { errorMessage }), { duration: 5000 });
    },
    onSuccess: () => {
      if (!disabledRefetch) {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === 'reservation' ||
            query.queryKey[0] === 'reservations'
        });
      }
    },

    retry: false
  });
};

export default usePatchReservation;
