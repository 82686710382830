import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '@/components/ui/drawer';
import useReservationActions from '@/hooks/useReservationActions';
import useGetStudentStatus, {
  StudentStatus
} from '@/hooks/useGetStudentStatus';
import { getGroup } from '@/lib/utils';
import { ReservationStatus } from '@/utils/reservations';
import { differenceInYears } from 'date-fns';
import { Eye } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ReservationInterface } from '../tables/tables.types';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import { getEmojiByStatus } from '@/utils/consumer';

interface StudentInterface {
  reservation: ReservationInterface;
}

const StudentAttendenceRow = ({ reservation }: StudentInterface) => {
  const { t } = useTranslation();
  const studentData = reservation.consumers[0];

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const { handleAbsent, handleCompleted, isPending } = useReservationActions({
    disabledRefetch: true
  });
  const studentStatus = useGetStudentStatus({
    studentData,
    currentReservationDate: new Date(reservation.startTime / 1000),
    currentReservationStatus: reservation.reservationStatus.name
  });

  const studentStatusEmojis = getEmojiByStatus(studentStatus);

  const checkPresent =
    reservation?.reservationStatus.name === ReservationStatus.Completed;
  const handlePresent = () => {
    if (checkPresent) {
      handleAbsent(reservation.id);
    } else {
      handleCompleted(reservation.id);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!studentData) {
    return <p>{t('noStudentData')}</p>;
  }

  const name = `${studentData.firstName} ${studentData.lastName}`;
  const age = differenceInYears(new Date(), new Date(studentData.birthdate));

  const details = [
    {
      label: t('activity.student.age'),
      value: age
    },
    {
      label: t('activity.student.group'),
      value: getGroup(studentData?.additionalData)
    },
    {
      label: t('levelLabel'),
      value: studentData.additionalData?.experience
    },
    {
      label: t('form.tShirtSize'),
      value: studentData.additionalData?.size
    },
    {
      label: t('authorizedToPickUp'),
      value: `${studentData.additionalData?.authorizedToPickUp?.firstName} ${studentData.additionalData?.authorizedToPickUp?.lastName}`
    },
    {
      label: t('form.medicalCoverage'),
      value: studentData.additionalData?.insurance
    },
    {
      label: t('responsible'),
      value: `${studentData.additionalData?.parents[0]?.firstName} ${studentData.additionalData?.parents[0]?.lastName} - ${studentData.additionalData?.parents[1]?.firstName} ${studentData.additionalData?.parents[1]?.lastName}`
    },
    {
      label: t('diseasesLabel'),
      values: studentData.additionalData?.diseases.filter(
        (disease: { answer: boolean }) => disease.answer
      )
    },
    {
      label: t('treatmentsLabel'),
      values: studentData.additionalData?.treatments.filter(
        (disease: { answer: boolean }) => disease.answer
      )
    },
    {
      label: t('form.holidayDate'),
      value: studentData.additionalData?.holidayDate
    },
    {
      label: t('common.isExtension'),
      value: studentData.additionalData?.isExtension
        ? t('form.yes')
        : t('form.no')
    },
    {
      label: t('isEmployeePartner'),
      value: studentData.additionalData?.isEmployeePartner
    },
    {
      label: t('isPartner'),
      value: studentData.additionalData?.isPartner
        ? t('form.yes')
        : t('form.no')
    }
  ];

  return (
    <Drawer direction={isDesktop ? 'right' : 'bottom'}>
      <div className="flex justify-between gap-2 p-2 rounded-md bg-accent">
        <div className="flex items-center w-full space-x-3 overflow-hidden whitespace-nowrap text-ellipsis">
          <Checkbox
            disabled={isPending}
            id={studentData.id}
            className="w-5 h-5 "
            checked={checkPresent}
            onClick={handlePresent}
          />
          <label htmlFor={studentData.id} className="font-medium truncate">
            {name}
          </label>
          {studentStatusEmojis.map(({ emoji, status }) => (
            <div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div>{emoji}</div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t(`studentStatus.${status}`)}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          ))}
        </div>
        <div className="flex items-center gap-2">
          <DrawerTrigger asChild>
            <Button variant="ghost" className="p-0">
              <Eye />
            </Button>
          </DrawerTrigger>
        </div>
      </div>
      <DrawerContent className="w-full max-w-[760px]">
        <div className="max-h-[80vh] lg:max-h-[100vh] overflow-auto no-scrollbar px-2">
          <DrawerHeader>
            <DrawerTitle className="font-bold text-center">
              {studentData.lastName}, {studentData.firstName}
            </DrawerTitle>
          </DrawerHeader>
          {details.map((item) => {
            return (
              <div
                key={item.value}
                className="flex justify-between gap-8 p-2 border-b border-accent"
              >
                <span className="font-bold opacity-70">{item.label}</span>
                <span className="text-right">
                  {item.value ||
                    (item.values
                      ? item.values.map(
                          (value: { question: string; answer: string }) => {
                            return (
                              <div
                                key={value.question}
                                className="flex flex-col mb-6"
                              >
                                <span className="italic font-semibold">
                                  {value.question}
                                </span>
                                <span>{value.answer}</span>
                              </div>
                            );
                          }
                        )
                      : '-')}
                </span>
              </div>
            );
          })}
          <DrawerFooter>
            <DrawerClose>
              <Button variant="outline" className="w-full">
                {t('modals.alert.close')}
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default StudentAttendenceRow;
