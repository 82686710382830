import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { useEffect } from 'react';

export type GetReservationsProps = {
  pagination: PaginationState;
  filtering: string;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
  organizationName?: string;
};

const getQueryOptions = ({
  pagination,
  filtering,
  sorting,
  columnFilters,
  organizationName
}: GetReservationsProps) => {
  return {
    queryKey: [
      'reservations',
      pagination,
      { search: filtering },
      sorting,
      columnFilters,
      organizationName
    ],
    refetchInterval: 100 * 5000,
    refetchOnReconnect: true,
    queryFn: () =>
      api.reservations.getPaginatedReservations({
        pagination,
        filtering,
        sorting,
        columnFilters
      })
  };
};

const useGetPaginatedReservations = (props: GetReservationsProps) => {
  const queryClient = useQueryClient();
  const organizationName = useOrganizationName();

  //prefetch next page
  useEffect(() => {
    queryClient.prefetchQuery(
      getQueryOptions({
        ...props,
        organizationName,
        pagination: {
          ...props.pagination,
          pageIndex: props.pagination.pageIndex + 1
        }
      })
    );
  }, [queryClient, props, organizationName]);

  return useQuery({
    ...getQueryOptions({ ...props, organizationName }),

    placeholderData: keepPreviousData
  });
};

export default useGetPaginatedReservations;
