import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';
import formParticipantDataSchema from '@/schemas/formParticipantData';
import { useRootStore } from '@/stores/root-store';
import { maxHeight, maxWeight, minHeight, minWeight } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormParticipantDataProps, TForm } from './FormParticipantData.types';

const FormParticipantData: React.FC<FormParticipantDataProps> = ({
  user,
  emailUser,
  setEmailUser,
  isPending,
  onSubmit
}) => {
  const { t } = useTranslation();
  //store
  const removeStep = useRootStore((store) => store.removeStep);
  const stepServicesData = useRootStore((store) => store.stepServicesData);
  const currentStep = useRootStore((store) => store.currentStep);

  const defaultValues = {
    participants: [
      ...Array(
        stepServicesData?.participants ? stepServicesData?.participants - 1 : 1
      )
    ].map(() => ({
      firstName: '',
      lastName: '',
      birthdate: undefined,
      height: 0,
      weight: 0
    })),
    user: {
      email: user?.email ? user.email : emailUser ? emailUser : '',
      firstName: user?.firstName ? user.firstName : '',
      lastName: user?.lastName ? user.lastName : '',
      birthdate: user?.birthdate ? new Date(user.birthdate) : undefined,
      height: user?.height ? user.height : 0,
      weight: user?.weight ? user.weight : 0,
      phone: user?.phone ? user.phone : '',
      identification: {
        number: user?.identification?.number
          ? user?.identification?.number
          : '',
        type: user?.identification?.type ? user?.identification?.type : ''
      },
      userIsParticipant: true
    }
  };
  const minAge = stepServicesData?.service?.minAge;
  const maxAge = stepServicesData?.service?.maxAge;
  const form = useForm<TForm>({
    resolver: zodResolver(formParticipantDataSchema({ minAge, maxAge })),

    defaultValues
  });

  const userEmail = form.watch('user.email');

  const handleBlur = async () => {
    const isEmail = await form.trigger('user.email');
    if (userEmail !== '' && isEmail) {
      setEmailUser(userEmail);
    }
  };

  const { fields } = useFieldArray({
    control: form.control,
    name: 'participants'
  });

  const userIsParticipant = form.getValues().user.userIsParticipant;

  const onBack = () => {
    removeStep(currentStep);
  };

  useEffect(() => {
    if (user) {
      form.reset(defaultValues);
    }
  }, [user]);

  return (
    <div>
      <Form {...form}>
        <h2 className="mb-6 font-bold">
          {t('newBooking.formParticipantData.title')}
        </h2>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          {/* User is participant Checkbox */}
          <div className="p-2">
            <FormField
              control={form.control}
              name={'user.userIsParticipant'}
              render={({ field }) => (
                <FormItem>
                  <div className="space-y-3">
                    <FormControl>
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id="userIsParticipant"
                          checked={!field.value}
                          onCheckedChange={() => {
                            // remove a participant when the user is participant
                            if (stepServicesData?.participants) {
                              const currentValue =
                                form.getValues().participants;
                              if (!field.value) {
                                form.setValue(
                                  'participants',
                                  currentValue.slice(1)
                                );
                              } else if (currentValue?.length === 0) {
                                form.setValue('participants', [
                                  {
                                    firstName: '',
                                    lastName: '',
                                    birthdate: new Date(),
                                    height: 0,
                                    weight: 0
                                  }
                                ]);
                              } else {
                                form.setValue('participants', [
                                  ...currentValue,
                                  {
                                    firstName: '',
                                    lastName: '',
                                    birthdate: new Date(),
                                    height: 0,
                                    weight: 0
                                  }
                                ]);
                              }
                            }

                            form.clearErrors();
                            field.onChange(!field.value);
                          }}
                        />
                        <FormLabel htmlFor="userIsParticipant" className="ml-2">
                          {t(
                            'newBooking.formParticipantData.userIsParticipantLabel'
                          )}
                        </FormLabel>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          {/* mail input */}
          <div className="p-2">
            <FormField
              control={form.control}
              name="user.email"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('form.email')}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="example@email.com"
                        type="email"
                        {...field}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* Forms */}

          <div className="space-y-6 md:container">
            {/* User Forms */}
            <div
              className="flex flex-col gap-8 p-6 border rounded-md border-primary"
              key={`user-participant`}
            >
              <h3 className="text-sm font-normal text-primary">
                {t('newBooking.formParticipantData.yourData')}
                {userIsParticipant
                  ? ` - ${t('newBooking.participant')} 1 - ${
                      stepServicesData?.service?.name
                    }`
                  : null}
              </h3>
              <FormField
                control={form.control}
                name={`user.firstName`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('form.firstName')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`user.lastName`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('form.lastName')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                key={'user.phone'}
                control={form.control}
                name={`user.phone`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('form.phone')}</FormLabel>
                      <FormControl>
                        <Input
                          type="tel"
                          {...field}
                          placeholder={t('common.placeholder.phone')}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              {userIsParticipant ? (
                <>
                  <FormField
                    control={form.control}
                    name={`user.birthdate`}
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-col">
                          <FormLabel>{t('form.birth')}</FormLabel>
                          <CalendarModal
                            value={field.value}
                            placeholder={t('form.birth')}
                            onAccept={field.onChange}
                            dropDownPicker
                          />
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name={`user.height`}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>{t('form.height')}</FormLabel>
                          <FormControl>
                            <Input
                              step="1"
                              type="number"
                              min={minHeight}
                              max={maxHeight}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name={`user.weight`}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>{t('form.weight')}</FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              min={minWeight}
                              max={maxWeight}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </>
              ) : (
                <>
                  <FormField
                    control={form.control}
                    key={'user.identification.type'}
                    name="user.identification.type"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <IdentificationTypeSelect
                            defaultValue={field?.value}
                            onValueChange={field.onChange}
                            field={field}
                          />
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="user.identification.number"
                    key="user.identification.number"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>
                            {t('identificationTypes.identificationNumber')}
                          </FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </>
              )}
            </div>

            {/* Participants Forms */}
            {fields.map((field, index) => {
              const isSelectedExistingParticipant = Boolean(field?.id);
              const disabledInputs = isSelectedExistingParticipant;
              return (
                <div
                  className="flex flex-col gap-8 p-6 border rounded-md border-primary"
                  key={`participant-${index + 1}`}
                >
                  <h3 className="text-sm font-normal text-primary">
                    {t('newBooking.participant') + ' '}
                    {userIsParticipant ? index + 2 : index + 1} -
                    {' ' + stepServicesData?.service?.name}
                  </h3>
                  <FormField
                    control={form.control}
                    name={`participants.${index}.firstName`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('form.firstName')}</FormLabel>
                        <FormControl>
                          <Input {...field} disabled={disabledInputs} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`participants.${index}.lastName`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('form.lastName')}</FormLabel>
                        <FormControl>
                          <Input {...field} disabled={disabledInputs} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`participants.${index}.birthdate`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col ">
                        <FormLabel>{t('form.birth')}</FormLabel>
                        <CalendarModal
                          disabledInputLabel={disabledInputs}
                          value={field.value}
                          onAccept={field.onChange}
                          placeholder={t('form.birth')}
                          dropDownPicker
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`participants.${index}.height`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('form.height')}</FormLabel>
                        <FormControl>
                          <Input
                            step="1"
                            type="number"
                            min={minHeight}
                            max={maxHeight}
                            {...field}
                            disabled={disabledInputs}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`participants.${index}.weight`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('form.weight')}</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            step="1"
                            min={minWeight}
                            max={maxWeight}
                            {...field}
                            disabled={disabledInputs}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              );
            })}
          </div>

          <div className="flex flex-col gap-2">
            <Button type="submit" className="w-full" disabled={isPending}>
              {isPending ? (
                <Loader className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                t('newBooking.continue')
              )}
            </Button>
            <Button
              type="button"
              variant="outline"
              className="w-full"
              onClick={onBack}
            >
              {t('newBooking.goBack')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default FormParticipantData;
